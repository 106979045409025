<template>
  <div class="app-sidebar">
    <div class="user-me">
      <img class="my-avatar" src="@/assets/images/logo.png" />
      <div class="my-name">Geyj</div>
    </div>
    <div class="nav-menu">
      <div
        :class="['menu-option', $route.path === '/' ? 'is-active' : '']"
        @click="changeRouter('/')"
      >
        <div class="icon-container">
          <img :style="{ width: '32px' }" src="@/assets/images/home.png" />
        </div>
        首页
      </div>
      <!-- <div
        :class="['menu-option', $route.path === '/calander' ? 'is-active' : '']"
        @click="changeRouter('/calander')"
      >
        <div class="icon-container">
          <img :style="{ width: '26px' }" src="@/assets/images/calander.png" />
        </div>
        日历
      </div> -->
      <div
        :class="[
          'menu-option',
          $route.path === '/life-note' ? 'is-active' : '',
        ]"
        @click="changeRouter('/life-note')"
      >
        <div class="icon-container">
          <img :style="{ width: '36px' }" src="@/assets/images/note.png" />
        </div>
        生活随笔
      </div>
      <div
        :class="[
          'menu-option',
          $route.path === '/code-note' ? 'is-active' : '',
        ]"
        @click="changeRouter('/code-note')"
      >
        <div class="icon-container">
          <img :style="{ width: '36px' }" src="@/assets/images/note.png" />
        </div>
        代码笔记
      </div>
      <!-- <div
        :class="['menu-option', $route.path === '/photo' ? 'is-active' : '']"
        @click="changeRouter('/photo')"
      >
        <div class="icon-container">
          <img :style="{ width: '25px' }" src="@/assets/images/photo.png" />
        </div>
        相册
      </div> -->
      <div
        :class="['menu-option', $route.path === '/about-me' ? 'is-active' : '']"
        @click="changeRouter('/about-me')"
      >
        <div class="icon-container">
          <img :style="{ width: '26px' }" src="@/assets/images/aboutMe.png" />
        </div>
        关于我
      </div>
      <div
        :class="['menu-option', $route.path === '/contact' ? 'is-active' : '']"
        @click="changeRouter('/contact')"
      >
        <div
          class="icon-container"
        >
          <img :style="{ width: '26px' }" src="@/assets/images/contact.png" />
        </div>
        联系
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "AppSidebar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState([]),
    ...mapGetters([]),
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    changeRouter(path) {
      if (path === this.$route.path) return;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-sidebar {
  width: 220px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid #dcdfe6;
  flex-shrink: 0;
  .user-me {
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .my-avatar {
      border-radius: 50%;
      width: 88px;
      height: 88px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      border: 3px solid #ffffff;
    }
    .my-name {
      font-weight: 700;
      margin-top: 12px;
      font-size: 16px;
    }
  }
  .nav-menu {
    flex: 1;
    overflow-y: overlay;
    margin-top: 60px;
    .is-active {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
    .menu-option {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 10px 20px;
      transition: background-color 0.5s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .icon-container {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
      }
    }
  }
}
</style>