import { render, staticRenderFns } from "./App.vue?vue&type=template&id=aa785518&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./App.vue?vue&type=style&index=0&id=aa785518&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "aa785518",
  "5a64837d"
  
)

export default component.exports