<template>
  <div class="note">
    <div class="note-sidebar">
      <div class="note-list">
        <el-tree
          class="note-tree"
          @node-click="nodeClickHandler"
          :data="notes"
          :props="defaultProps"
          :expand-on-click-node="false"
          :auto-expand-parent="true"
          :default-expand-all="false"
          :default-expanded-keys="defaultExpandedKeys"
          node-key="id"
        >
          <div :class="['custom-tree-node', activeId === data.id ? 'is-active' : '']" slot-scope="{ node, data }">
            <div :class="[node.level === 1 ? 'note-type' : 'note-name']">
              {{ data.name }}
            </div>
          </div>
        </el-tree>
      </div>
    </div>
    <div class="note-markdown" ref="note-markdown">
      <div ref="markdown-body" class="markdown-body" v-html="markdown"></div>
    </div>
  </div>
</template>

<script>
//爬虫 docs
import Basic from "@/assets/markdowns/reptile/basic.md"
import Cheerio from "@/assets/markdowns/reptile/cheerio.md"
import Puppeteer from "@/assets/markdowns/reptile/puppeteer.md"
//AI docs
import StableDiffusion from "@/assets/markdowns/ai/stableDiffusion.md"
import ChatGPT from "@/assets/markdowns/ai/chatGPT.md"
import Tensorflow from "@/assets/markdowns/ai/tensorflow.md"
import Program from "@/assets/markdowns/ai/program.md"
import Research from "@/assets/markdowns/ai/research.md"
//Test docs
import Test1 from "@/assets/markdowns/test/1.md"
//Developer docs
import Developer1 from "@/assets/markdowns/developer/1.md"
import Developer2 from "@/assets/markdowns/developer/2.md"
import Developer3 from "@/assets/markdowns/developer/3.md"
//Server docs
import CentOS from "@/assets/markdowns/server/setup.md"
import Frp from "@/assets/markdowns/server/frp.md"
import Ngnix from "@/assets/markdowns/server/ngnix.md"
import Pm2 from "@/assets/markdowns/server/pm2.md"
import SSL from "@/assets/markdowns/server/ssl.md"
import SSO from "@/assets/markdowns/server/sso.md"
//Visual Studio Code docs
import VisualStudioCode1 from "@/assets/markdowns/vscode/1.md"
import VisualStudioCode2 from "@/assets/markdowns/vscode/2.md"
import VisualStudioCode3 from "@/assets/markdowns/vscode/3.md"
//JavaScript docs
import JavaScriptDrag from "@/assets/markdowns/javascript/drag.md"
import JavaScriptModel from "@/assets/markdowns/javascript/model.md"
import JavaScriptSerial from "@/assets/markdowns/javascript/serial.md"
import JavaScriptWebWorker from "@/assets/markdowns/javascript/webworker.md"
import JavaScriptWebSocket from "@/assets/markdowns/javascript/websocket.md"
//TypeScript docs
import TypeScript1 from "@/assets/markdowns/typescript/1.md"
//ChromeExtension docs
import ChromeExtension1 from "@/assets/markdowns/chromeExtensions/1.md"
import ChromeExtension2 from "@/assets/markdowns/chromeExtensions/2.md"
import ChromeExtension3 from "@/assets/markdowns/chromeExtensions/3.md"
//Vue docs
import Vue1 from "@/assets/markdowns/vue/1.md"
import Vue2 from "@/assets/markdowns/vue/2.md"
import Vue3 from "@/assets/markdowns/vue/3.md"
//Vue3 docs
import Vue3_0 from "@/assets/markdowns/vue3/0.md"
import Vue3_1 from "@/assets/markdowns/vue3/1.md"
import Vue3_2 from "@/assets/markdowns/vue3/2.md"
import Vue3_3 from "@/assets/markdowns/vue3/3.md"
import Vue3_4 from "@/assets/markdowns/vue3/4.md"
import Vue3_5 from "@/assets/markdowns/vue3/5.md"
import Vue3_6 from "@/assets/markdowns/vue3/6.md"
import Vue3_7 from "@/assets/markdowns/vue3/7.md"
import Vue3_8 from "@/assets/markdowns/vue3/8.md"
import Vue3_9 from "@/assets/markdowns/vue3/9.md"
//NodeJs docs
import NodeJs1 from "@/assets/markdowns/nodeJs/1.md"
import NodeJs2 from "@/assets/markdowns/nodeJs/2.md"
import Express from "@/assets/markdowns/nodeJs/express.md"
import Nest from "@/assets/markdowns/nodeJs/nest.md"
//Electron docs
import ElectronInstall from "@/assets/markdowns/electron/install.md"
import ElectronError from "@/assets/markdowns/electron/error.md"
import ElectronProtocol from "@/assets/markdowns/electron/protocol.md"
import ElectronSign from "@/assets/markdowns/electron/sign.md"
import ElectronUpdater from "@/assets/markdowns/electron/updater.md"
import ElectronFunction from "@/assets/markdowns/electron/function.md"
import ElectronEcology from "@/assets/markdowns/electron/ecology.md"
//Webpack docs
import Webpack1 from "@/assets/markdowns/webpack/1.md"
import Webpack2 from "@/assets/markdowns/webpack/2.md"
import Webpack3 from "@/assets/markdowns/webpack/3.md"
import Webpack4 from "@/assets/markdowns/webpack/4.md"
import Webpack5 from "@/assets/markdowns/webpack/5.md"
import Webpack6 from "@/assets/markdowns/webpack/6.md"
import Webpack7 from "@/assets/markdowns/webpack/7.md"
import Webpack8 from "@/assets/markdowns/webpack/8.md"
import Webpack9 from "@/assets/markdowns/webpack/9.md"
import Webpack10 from "@/assets/markdowns/webpack/10.md"
import Webpack11 from "@/assets/markdowns/webpack/11.md"
//Flutter docs
import FlutterWidget from "@/assets/markdowns/flutter/widget.md"
//面试 docs
import MianShi1 from "@/assets/markdowns/mianshi/1.md"
import MianShi2 from "@/assets/markdowns/mianshi/2.md"
import Vue from "vue"
import { Tree } from "element-ui"
Vue.use(Tree)
import "github-markdown-css/github-markdown-light.css"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
  name: "CodeNote",
  data() {
    return {
      markdown: "",
      markdownEl: null,
      notes: [
        // {
        //   id: "mianshi",
        //   name: "面试",
        //   children: [
        //     { id: "mianshi-1", name: "面试要求", markdown: MianShi1 },
        //     { id: "mianshi-2", name: "题目", markdown: MianShi2 },
        //   ],
        // },
        {
          id: "ai",
          name: "AI",
          children: [
            { id: "ai-tensorflow", name: "Tensorflow", markdown: Tensorflow },
            { id: "ai-chatgpt", name: "ChatGPT", markdown: ChatGPT },
            { id: "ai-sd", name: "Stable Diffusion", markdown: StableDiffusion },
            { id: "ai-program", name: "AI编程", markdown: Program },
            { id: "ai-research", name: "调研", markdown: Research }
          ]
        },
        {
          id: "developer",
          name: "Developer",
          children: [
            { id: "developer-1", name: "前端开发者环境", markdown: Developer1 },
            { id: "developer-2", name: "前端开发规范", markdown: Developer2 },
            { id: "developer-3", name: "前后端对接规范", markdown: Developer3 }
          ]
        },
        {
          id: "test",
          name: "Test",
          children: [{ id: "test-1", name: "单元测试", markdown: Test1 }]
        },
        {
          id: "server",
          name: "Server",
          children: [
            { id: "server-centOS", name: "CentOS", markdown: CentOS },
            { id: "server-frp", name: "Frp", markdown: Frp },
            { id: "server-ngnix", name: "Ngnix", markdown: Ngnix },
            { id: "server-pm2", name: "Pm2", markdown: Pm2 },
            { id: "server-ssl", name: "SSL", markdown: SSL },
            { id: "server-sso", name: "SSO", markdown: SSO }
          ]
        },
        {
          id: "javascript",
          name: "JavaScript",
          children: [
            { id: "javascript-drag", name: "拖拽", markdown: JavaScriptDrag },
            { id: "javascript-model", name: "模式", markdown: JavaScriptModel },
            {
              id: "javascript-serial",
              name: "串口通信",
              markdown: JavaScriptSerial
            },
            { id: "javascript-webworker", name: "Web Worker", markdown: JavaScriptWebWorker },
            { id: "javascript-websocket", name: "WebSocket", markdown: JavaScriptWebSocket }
          ]
        },
        {
          id: "chrome-extension",
          name: "Chrome Extension",
          children: [
            { id: "chrome-extension-1", name: "Manifest", markdown: ChromeExtension1 },
            { id: "chrome-extension-2", name: "构建", markdown: ChromeExtension2 },
            { id: "chrome-extension-3", name: "应用", markdown: ChromeExtension3 }
          ]
        },
        {
          id: "nodejs",
          name: "NodeJs",
          children: [
            { id: "nodejs-1", name: "版本", markdown: NodeJs1 },
            { id: "nodejs-2", name: "功能", markdown: NodeJs2 },
            { id: "nodejs-express", name: "Express", markdown: Express },
            { id: "nodejs-nest", name: "Nest", markdown: Nest }
          ]
        },
        {
          id: "reptile",
          name: "爬虫",
          children: [
            { id: "basic", name: "基础", markdown: Basic },
            { id: "cheerio", name: "Cheerio", markdown: Cheerio },
            { id: "puppeteer", name: "Puppeteer", markdown: Puppeteer }
          ]
        },
        {
          id: "electron",
          name: "Electron",
          children: [
            {
              id: "electron-install",
              name: "快速创建",
              markdown: ElectronInstall
            },
            {
              id: "electron-error",
              name: "踩坑记录",
              markdown: ElectronError
            },
            {
              id: "electron-protocol",
              name: "协议",
              markdown: ElectronProtocol
            },
            { id: "electron-sign", name: "签名公证", markdown: ElectronSign },
            {
              id: "electron-updater",
              name: "自动更新",
              markdown: ElectronUpdater
            },
            {
              id: "electron-function",
              name: "功能",
              markdown: ElectronFunction
            },
            {
              id: "electron-ecology",
              name: "生态",
              markdown: ElectronEcology
            }
          ]
        },
        {
          id: "webpack",
          name: "Webpack",
          children: [
            { id: "webpack-1", name: "项目前期准备", markdown: Webpack1 },
            { id: "webpack-2", name: "开发服务器", markdown: Webpack2 },
            { id: "webpack-3", name: "项目打包", markdown: Webpack3 },
            { id: "webpack-4", name: "Loaders", markdown: Webpack4 },
            { id: "webpack-5", name: "解析(Resolve)", markdown: Webpack5 },
            { id: "webpack-6", name: "添加React+TypeScript", markdown: Webpack6 },
            { id: "webpack-7", name: "代码分离", markdown: Webpack7 },
            { id: "webpack-8", name: "环境相关", markdown: Webpack8 },
            { id: "webpack-9", name: "缓存", markdown: Webpack9 },
            { id: "webpack-10", name: "创建库和发布", markdown: Webpack10 },
            { id: "webpack-11", name: "查漏补缺", markdown: Webpack11 }
          ]
        },
        {
          id: "vue3",
          name: "Vue3生态",
          children: [
            { id: "vue3-0", name: "项目结构", markdown: Vue3_0 },
            { id: "vue3-1", name: "Pinia", markdown: Vue3_1 },
            { id: "vue3-2", name: "Axios", markdown: Vue3_2 },
            { id: "vue3-3", name: "Vite", markdown: Vue3_3 },
            { id: "vue3-4", name: "Vue Router", markdown: Vue3_4 },
            { id: "vue3-5", name: "Sass", markdown: Vue3_5 },
            { id: "vue3-6", name: "Element Plus", markdown: Vue3_6 },
            { id: "vue3-7", name: "Echarts", markdown: Vue3_7 },
            { id: "vue3-8", name: "Vue3", markdown: Vue3_8 },
            { id: "vue3-9", name: "开发记录", markdown: Vue3_9 }
          ]
        },
        {
          id: "vue",
          name: "Vue",
          children: [
            { id: "vue-1", name: "变化侦测", markdown: Vue1 },
            { id: "vue-2", name: "构建Vue组件库", markdown: Vue2 },
            { id: "vue-3", name: "搭建Vue SSR", markdown: Vue3 }
          ]
        },
        {
          id: "typeccript",
          name: "TypeScript",
          children: [{ id: "typeccript-1", name: "配置", markdown: TypeScript1 }]
        },
        {
          id: "flutter",
          name: "Flutter",
          children: [{ id: "flutter-widget", name: "Widget", markdown: FlutterWidget }]
        },
        {
          id: "vscode",
          name: "Visual Studio Code",
          children: [
            { id: "vscode-1", name: "插件", markdown: VisualStudioCode1 },
            { id: "vscode-2", name: "快捷键", markdown: VisualStudioCode2 },
            { id: "vscode-3", name: "异常", markdown: VisualStudioCode3 }
          ]
        }
      ],
      defaultProps: {
        children: "children",
        label: "name"
      },
      activeId: "",

      defaultExpandedKeys: []
    }
  },
  computed: {
    ...mapState([]),
    ...mapGetters([])
  },
  created() {},
  mounted() {
    const id = window.sessionStorage.getItem("noteId")
    if (id) {
      const dir = this.notes.filter((note) => id.includes(note.id))[0]
      if (dir) {
        const notes = dir.children
        const note = notes.filter((note) => note.id === id)[0]
        if (note) {
          this.switchMarkdown(note)
        } else {
          this.switchMarkdown(this.notes[0].children[0])
        }
      } else {
        this.switchMarkdown(this.notes[0].children[0])
      }
    } else {
      this.switchMarkdown(this.notes[1].children[0])
    }
    // await this.$nextTick();
    // this.markdownEl = this.$refs["markdown-body"];
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    async switchMarkdown(md) {
      this.defaultExpandedKeys = [md.id]
      window.sessionStorage.setItem("noteId", md.id)
      this.activeId = md.id
      this.markdown = md.markdown
      await this.$nextTick()
      this.$refs["markdown-body"].scrollTop = 0
      // hljs.highlightAll();
    },
    nodeClickHandler(data, node) {
      if (node.level === 1) {
        this.switchMarkdown(data.children[0])
      } else if (node.level === 2) {
        this.switchMarkdown(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  display: flex;
  height: 100%;
  width: 100%;

  .note-sidebar {
    width: 220px;
    height: 100%;
    overflow-y: overlay;
    flex-shrink: 0;
    border-right: 1px solid #dcdfe6;

    .note-list {
      .el-tree {
        background: inherit;
      }

      .note-tree {
        ::v-deep.el-tree-node:focus > .el-tree-node__content {
          background-color: inherit;
        }

        ::v-deep.el-tree-node__content {
          height: 36px;
          border-radius: 4px;
          transition: color 1.5s;

          &:hover {
            background-color: inherit;

            .note-name {
              color: #0b58d2 !important;
            }
          }
        }

        .is-active {
          .note-name {
            color: #0b58d2 !important;
          }
        }

        .custom-tree-node {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;

          .note-name {
            font-size: 14px;
          }

          .note-type {
            color: #2c3e50;
            font-weight: bold;
            font-size: 15px;
          }
        }
      }
    }
  }

  .note-markdown {
    padding: 20px 0 20px 20px;
    width: calc(100% - 220px);

    .markdown-body {
      height: 100%;
      width: 100%;
      overflow: overlay;
    }
  }
}
</style>
