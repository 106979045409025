<template>
  <div class="note">
    <div class="note-header" v-show="markdown">
      <el-page-header @back="markdown = ''"> </el-page-header>
    </div>
    <div class="note-list" v-show="!markdown">
      <div class="note-option" v-for="note in notes" :key="note.id" @click="switchMarkdown(note)">
        <img class="option-image" :src="note.image" />
        <div class="option-content">
          <div class="content-text text-overflow-2">
            <div class="markdown-body" v-html="note.markdown"></div>
          </div>
          <div class="option-date">{{ note.date }}</div>
        </div>
      </div>
    </div>
    <div class="note-markdown" v-show="markdown">
      <div class="markdown-body" v-html="markdown"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { PageHeader } from 'element-ui';
Vue.use(PageHeader);
//life docs
import Life1 from "@/assets/markdowns/life/1.md";
import Life2 from "@/assets/markdowns/life/2.md";
import Life3 from "@/assets/markdowns/life/3.md";
import Life4 from "@/assets/markdowns/life/4.md";
export default {
  name: "LifeNote",
  data() {
    return {
      markdown: "",
      notes: [
      {
          id: "4",
          name: "想到什么写什么",
          date: "2024年1月31日",
          markdown: Life4,
          // image: require("@/assets/images/life/life-2.jpg")
        },
        {
          id: "3",
          name: "回顾2023，展望2024",
          date: "2023年12月24日",
          markdown: Life3,
          // image: require("@/assets/images/life/life-2.jpg")
        },
        {
          id: "2",
          name: "西坡千岛湖",
          date: "2023年2月27日",
          markdown: Life2,
          image: require("@/assets/images/life/life-2.jpg")
        },
        {
          id: "1",
          name: "人生中的第一次摆摊",
          date: "2023年2月12日",
          markdown: Life1,
          image: require("@/assets/images/life/life-1.jpg")
        },
      ],
    };
  },
  methods: {
    switchMarkdown(md) {
      this.markdown = md.markdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  height: 100%;
  width: 100%;
  overflow-y: overlay;
  padding: 20px;

  .note-header {
    padding: 0 0 20px 0;
  }

  .note-list {
    height: 100%;
    display: flex;
    align-items: flex-start;

    .note-option {
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 0 5px #ccc;
      transition: 0.5s;
      overflow: hidden;
      margin-right: 12px;
      margin-bottom: 12px;
      width: 240px;

      &:hover {
        box-shadow: 0 0 16px #ccc;
      }

      .option-image {
        width: 240px;
        height: 160px;
      }

      .option-content {
        padding: 10px;

        .content-text {
          ::v-deep.markdown-body {
            h3 {
              font-size: 14px;
              margin-bottom: 6px;
            }

            p {
              font-size: 12px;
            }
          }
        }

        .option-date {
          font-size: 12px;
          margin-top: 6px;
        }
      }
    }
  }

  .note-markdown {
    height: calc(100% - 44px);
    width: 100%;
    overflow: overlay;
    padding: 0 20px 0 0;

    .markdown-body {
      height: 100%;
      width: 100%;
    }
  }
}
</style>