import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
import '@/assets/styles/main.scss'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI)
Vue.config.productionTip = false

export default function createApp() {
    const router = createRouter();
    const store = createStore();
    const app = new Vue({
        router,
        store,
        render: h => h(App)
    })
    return { app, router, store };
}

