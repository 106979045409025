<template>
  <div class="photo">
    <div class="photo-option">
      <img class="option-image" src="@/assets/images/photo/javascript.png" />
      <div class="option-description">JavaScript</div>
    </div>
    <div class="photo-option">
      <img class="option-image" src="@/assets/images/photo/javascript.png" />
      <div class="option-description">JavaScript</div>
    </div>
    <div class="photo-option">
      <img class="option-image" src="@/assets/images/photo/javascript.png" />
      <div class="option-description">JavaScript</div>
    </div>
    <div class="photo-option">
      <img class="option-image" src="@/assets/images/photo/javascript.png" />
      <div class="option-description">JavaScript</div>
    </div>
    <div class="photo-option">
      <img class="option-image" src="@/assets/images/photo/javascript.png" />
      <div class="option-description">JavaScript</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "Photo",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState([]),
    ...mapGetters([]),
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
.photo {
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow-y: overlay;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  .photo-option {
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 5px #ccc;
    transition: 0.5s;
    width: 300px;
    overflow: hidden;
    margin-right: 12px;
    margin-bottom: 12px;
    &:hover {
      box-shadow: 0 0 16px #ccc;
    }
    .option-image {
      width: 100%;
    }
    .option-description {
      text-align: center;
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
