<template>
  <div class="about-me">
    <div class="about-option">
      <div class="about-label">自我介绍</div>
      <div class="about-value">我是一名前端开发工程师，目前就职于宁波一家互联网公司，性格开朗，为人随和🤭</div>
    </div>
    <div class="about-option">
      <div class="about-label">爱好</div>
      <div class="about-value">
        <ul>
          <li>唱歌</li>
          <li>看风景</li>
        </ul>
      </div>
    </div>
    <!-- <div class="about-option">
      <div class="about-label">优点</div>
      <div class="about-value">
        <ul>
          <li>主观能动性(想了解一项事物，会通过各种途径，如看书（行为认知疗法，非暴力沟通）)</li>
          <li>自驱力（自我激励）</li>
          <li>性格开朗，随和（与人相处）</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">缺点</div>
      <div class="about-value">
        <ul>
          <li>胖</li>
          <li>近视</li>
          <li>决策力</li>
          <li>对未知的恐惧，不够乐观和自信</li>
        </ul>
      </div>
    </div> -->
    <div class="about-option">
      <div class="about-label">技能</div>
      <div class="about-value">
        <ul>
          <li>Web应用开发</li>
          <li>桌面应用开发</li>
          <li>小程序开发</li>
          <li>Chrome浏览器插件开发</li>
          <li>Node.js开发</li>
          <li>服务器相关</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">经验</div>
      <div class="about-value">
        <ul>
          <li>独立负责中大型项目的开发和团队管理（3人）</li>
          <li>前端基础建设搭建（方法库@geyj/web-utils、组件库@geyj/components、脚手架@geyj/app-cli、技术文档https://gyjtiancai.github.io/docs-front/，监控SDK、监控后台、内网服务集群和穿透等）</li>
          <li>Web自动化</li>
          <li>亦云web端和桌面端：https://admin.effio.cn/pc/platform2/</li>
          <!-- <li>美公系统：https://z.mumkey.com/</li> -->
          <li>个人博客：https://www.blog.okoknb.cn/</li>
          <li>即时通讯以及性能优化</li><!-- 1.消息轰炸：前端要维护正在发送中的消息队列，收到消息还要改变对应消息的状态（已读未读等），期间还有IO操作（IO异步，限制消息的时间间隔）（后续还可以考虑开辟一个消息进程或者线程，单独处理密集型任务） 2.频繁切换会话加载消息（磁盘读取 -> 内存存储和读取（应考虑会话消息存储上限问题），后续想法（防抖）） -->
          <li>大文件解析和上传</li><!-- Web Worker开辟新线程 分片上传 -->
          <li>表格在线配置工具</li>
          <li>自定义表单，表格自定义公式等</li><!-- 相对较难的业务 -->
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">想做的事</div>
      <div class="about-value">
        <ul>
          <li>学弹钢琴 - 已实现</li>
          <li>坐飞机</li>
          <li>去新疆</li>
          <li>机器学习 - 正在进行</li>
          <li>记账App（React Native）</li>
          <li>监控后台（Nestjs）</li>
        </ul>
      </div>
    </div>
    <!-- <div class="about-option">
      <div class="about-label">近期关注</div>
      <div class="about-value">
        <ul>
          <li>Chat-Gpt</li>
          <li>Htmx</li>
        </ul>
      </div>
    </div> -->
    <div class="about-option">
      <div class="about-label">联系方式</div>
      <div class="about-value">
        <ul>
          <li>QQ：961211746</li>
          <li>微信：yjyjyjyjyjgyj</li>
          <li>邮箱：961211746@qq.com</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "AboutMe",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState([]),
    ...mapGetters([]),
  },
  created() { },
  beforeDestroy() { },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
.about-me {
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  .about-option {
    margin-bottom: 20px;

    .about-label {
      color: #666;
      line-height: 1.4;
      font-weight: 700;
      font-size: 18px;
    }

    .about-value {
      margin-top: 12px;

      ul {
        margin-left: 20px;

        li {
          list-style: disc;
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
    }
  }
}</style>
