<template>
  <div class="app-header" :style="{
    boxShadow: '0 1px 4px 1px rgb(0, 0, 0, 0.05)',
  }">
    <div class="header-title">Keep Smile</div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  components: {},
  data() {
    return {};
  },
  computed: {

  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  .header-title {
    font-weight: 700;
    font-size: 18px;
    flex-shrink: 0;
    width: 120px;
  }
}
</style>