import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import LifeNote from '../views/LifeNote.vue'
import CodeNote from '../views/CodeNote.vue'
import AboutMe from '../views/AboutMe.vue'
import Contact from '../views/Contact.vue'
import Photo from '../views/Photo.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'NotFound',
    redirect: '/',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/life-note',
    name: 'LifeNote',
    component: LifeNote
  },
  {
    path: '/code-note',
    name: 'CodeNote',
    component: CodeNote
  },
  {
    path: '/photo',
    name: 'Photo',
    component: Photo
  },
  {
    path: '/about-me',
    name: 'AboutMe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutMe
    // component: () => import(/* webpackChunkName: "about-me" */ '../views/AboutMe.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
]

export default function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes
  })
}