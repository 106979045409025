<template>
  <div class="contact">
    <div class="contact-option">
      <div class="contact-label">QQ</div>
      <div class="contact-value">961211746</div>
    </div>
    <div class="contact-option">
      <div class="contact-label">邮箱</div>
      <div class="contact-value">961211746@qq.com</div>
    </div>
    <div class="contact-option">
      <div class="contact-label">微信号</div>
      <div class="contact-value">yjyjyjyjyjgyj</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "Contact",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState([]),
    ...mapGetters([]),
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
.contact {
  height: 100%;
  width: 100%;
  padding: 20px;
  .contact-option {
    margin-bottom: 20px;
    .contact-label {
      color: #666;
      line-height: 1.4;
      font-weight: 700;
      font-size: 18px;
    }
    .contact-value {
      margin-top: 12px;
    }
  }
}
</style>
