<template>
  <div class="home">
    <!-- <div class="motto-en">Happiness consists in contentment</div>
    <div class="motto-cn">知足常乐</div> -->
    <div class="motto-en">The best time to plant a tree was ten years ago, followed by now</div>
    <div class="motto-cn">种一棵树最好的时间是十年前，其次是现在。</div>
    <canvas id="canvas-bg" height="650" width="940"></canvas>
  </div>
</template>

<script>
// import {
//   print, 
//   getFileType,
//   networkFileToBlob,
//   blobToBase64,
//   downloadBlob,
//   downloadNetworkFile,
//   previewOffice
// } from '@geyj/web-utils'
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  async mounted() {
    this.createCanvas()
    // print({printDom:document.body})
    // console.log(getFileType('https://www.sadsadssa.txt'))
    // const fileUrl = 'https://p26.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_c100122a4e529220fbd668df930b8307.jpeg?from=2956013662'
    // const fileName = '我的头像'
    // const blob = await networkFileToBlob(fileUrl)
    // console.log(blob)
    // const base64 = await blobToBase64(blob)
    // console.log(base64)
    // downloadBlob({ blob, fileName })
    // downloadNetworkFile({ fileUrl, fileName })
    // previewOffice(fileUrl)
  },
  methods: {
    createCanvas() {
      const Canvas = document.getElementById("canvas-bg");
      const Width = Canvas.width;
      const Height = Canvas.height;
      if (!Canvas.getContext) return;
      const ctx = Canvas.getContext("2d");
      class RandomParticles {
        constructor(x, y) {
          this.x = x; //x坐标
          this.y = y; //y坐标
          this.r = 10; //半径
          this.circles = [];
        }
        //绘制球
        draw() {
          const x = Math.random() * this.x;
          const y = Math.random() * this.y;
          const r = this.r;
          const color = `rgba(${Math.random() * 256},${Math.random() * 256},${Math.random() * 256
            },${Math.random()})`;
          this.circles.push({
            x: x,
            y: y,
            r: r,
            color: color,
            isAddX: true,
            isAddY: true,
          });
          ctx.beginPath();
          ctx.arc(x, y, r, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fillStyle = color;
          ctx.fill();
        }
        //球移动规则
        move(speed) {
          this.circles.forEach((circle) => {
            if (circle.isAddX) {
              if (circle.x < Width - this.r) {
                circle.x = circle.x + speed;
              } else {
                circle.isAddX = false;
              }
            } else {
              if (circle.x > 0 + this.r) {
                circle.x = circle.x - speed;
              } else {
                circle.isAddX = true;
              }
            }
            if (circle.isAddY) {
              if (circle.y < Height - this.r) {
                circle.y = circle.y + speed;
              } else {
                circle.isAddY = false;
              }
            } else {
              if (circle.y > 0 + this.r) {
                circle.y = circle.y - speed;
              } else {
                circle.isAddY = true;
              }
            }
            ctx.beginPath();
            ctx.arc(circle.x, circle.y, circle.r, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fillStyle = circle.color;
            ctx.fill();
          });
        }
        //绘制指定个数的球
        init(count) {
          for (let i = 0; i < count; i++) {
            this.draw();
          }
        }
      }
      const particles = new RandomParticles(Width, Height);
      particles.init(20);
      function anmite() {
        //清空画布
        ctx.clearRect(0, 0, Width, Height);
        particles.move(1);
        requestAnimationFrame(anmite);
      }
      anmite();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  padding: 20px;

  .motto-en {
    font-size: 18px;
    font-weight: 600;
  }

  .motto-cn {
    margin-top: 12px;
    font-size: 15px;
    color: #a0a0a0;
    font-weight: 600;
  }

  // #canvas-bg {
  //   width: 100%;
  //   height: calc(100% - 50px);
  // }
}
</style>
