<template>
  <div id="app">
    <canvas id="particles"></canvas>
    <div class="app-container">
      <AppHeader></AppHeader>
      <div class="app-body">
        <AppSidebar></AppSidebar>
        <div class="app-main">
          <div class="app-main-container">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";
export default {
  components: {
    AppHeader,
    AppSidebar,
  },
  data() {
    return {};
  },
  mounted() {

  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  color: #777;
  min-width: 1200px;
  position: relative;
  overflow-y: hidden;

  .app-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 1200px;
    height: 100%;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
    margin: 0 auto;

    .app-body {
      display: flex;
      height: calc(100% - 50px);
      width: 100%;
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);

      .app-main {
        // flex: 1;
        height: 100%;
        width: calc(100% - 220px);
        padding: 3px 0 0 0;

        .app-main-container {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}</style>
